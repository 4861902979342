import { Dialog } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';
import { getZoneList } from '../../pages/aiBotCalling/selector';
import Close from '../../assets/icons/cross';

const ZoneModal = ({open,handleClose}:any) => {
    
  const zoneDetail = useSelector((state) => getZoneList(state));
 
  return (
   <>
   <Dialog className='stepsDialog' open={open} onClose={handleClose}>
    <div className='dialogWrapper'>
    <span role="link" onClick={handleClose} className="closeDialog">
            <Close />
          </span>
    <div className='dialogWrapper__header'>
   <h2>Zone Call detail</h2>
   </div>
   <div className='dialogWrapper__info'>
    <div className='dialogWrapper__table'>
    <table>
        <tr>
            <th>Zone 1:</th>
            <td>{zoneDetail.zone1Count}</td>
        </tr>
        <tr>
            <th>Zone 2:</th>
            <td>{zoneDetail.zone2Count}</td>
        </tr>
        <tr>
            <th>Zone 3:</th>
            <td>{zoneDetail.zone3Count}</td>
        </tr>
    </table>
    </div>
   </div>
   </div>
   </Dialog>
   </>
  )
}

export default ZoneModal
